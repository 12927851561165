/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import BackgroundSlider from "react-background-slider"
import IRPageWrapper from "../../layouts/IRPageWrapper"
import IRNav from "../../components/IRNav"
import BGImg from "./ir.jpg"
import HomeLogo from "../../components/HomeLogo"
import IRBG from "../../components/IRBG"
import GovernanceReport from "./governancereport2023.pdf"
import BoardCharter from "./boardcharter.pdf"
import TermsOfReference from "./termsofreference.pdf"
import Ethics from "./ethics.pdf"
import Whitsle from "./whistle-2023.pdf"
import RemunerationPolicy from "./remunerationpolicy.pdf"
import RiskPolicy from "./riskpolicy.pdf"
import Tender from "./tender.pdf"
import Risk from "./risk.pdf"
import Fitproper from "./fitproper.pdf"

export default class CorporateGovernancePage extends Component {
  render() {
    // const { data } = this.props
    // const { group: events } = data.allMarkdownRemark

    return (
      <IRPageWrapper>
        <IRBG />

        <Helmet>
          <title>InvestorCentre - EA Technique</title>
        </Helmet>
        <section
          className="hero is-fullheight"
          style={{ display: `inline-block` }}
        >
          <section className="hero is-small">
            <div className="columns">
              <div className="column is-offset-1">
                <div className="section">
                  <HomeLogo />
                  <h1 className="title" id="title-line">
                    Corporate Governance
                  </h1>
                  <IRNav />
                </div>
              </div>
            </div>
          </section>
          <div className="container" style={{ position: `initial`, zIndex: 1 }}>
            <section className="section" style={{ paddingLeft: `10%` }}>
              <p>
                E.A. Technique remains steadfast in its commitment to excellence
                in corporate governance standards. We adhere to the principles
                and best practices of corporate governance. It is fundamental
                part of how we discharge our duties and responsibilities towards
                protecting and enhancing our shareholders’ value as well as
                ensuring that we achieve long term sustainable financial
                performance.
              </p>
              <div
                className="columns"
                style={{ marginTop: `30px`, minHeight: `120px` }}
              >
                <div className="column">
                  <a href={Fitproper} download>
                    <h1
                      className="title is-6"
                      style={{
                        borderTop: `3px solid #004D9B`,
                      }}
                    >
                      Fit & Proper Policy 2022
                    </h1>
                  </a>
                </div>
                <div className="column">
                  <a href={GovernanceReport} download>
                    <h1
                      className="title is-6"
                      style={{
                        borderTop: `3px solid #004D9B`,
                      }}
                    >
                      CG Report 2023
                    </h1>
                  </a>
                </div>
                <div className="column">
                  <a href={BoardCharter} download>
                    <h1
                      className="title is-6"
                      style={{
                        borderTop: `3px solid #004D9B`,
                      }}
                    >
                      Board Charter 2019
                    </h1>
                  </a>
                </div>
                <div className="column">
                  <a href={TermsOfReference} download>
                    <h1
                      className="title is-6"
                      style={{
                        borderTop: `3px solid #004D9B`,
                      }}
                    >
                      Terms of Reference of The Audit, Nomination & Remuneration
                      Committee
                    </h1>
                  </a>
                </div>
                
              </div>

              <div className="columns">
                <div className="column is-3">
                  <a href={Whitsle} download>
                    <h1
                      className="title is-6"
                      style={{
                        borderTop: `3px solid #004D9B`,
                      }}
                    >
                      Whistle-Blowing Policy 
                    </h1>
                  </a>
                </div>
                <div className="column is-3">
                  <a href={RemunerationPolicy} download>
                    <h1
                      className="title is-6"
                      style={{
                        borderTop: `3px solid #004D9B`,
                      }}
                    >
                      Remuneration Policy 2019
                    </h1>
                  </a>
                </div>
                <div className="column is-3">
                  <a href={RiskPolicy} download>
                    <h1
                      className="title is-6"
                      style={{
                        borderTop: `3px solid #004D9B`,
                      }}
                    >
                      Risk Policy
                    </h1>
                  </a>
                </div>
                <div className="column is-3">
                  <a href={Tender} download>
                    <h1
                      className="title is-6"
                      style={{
                        borderTop: `3px solid #004D9B`,
                      }}
                    >
                      Terms of Reference – Tender Board Committee
                    </h1>
                  </a>
                </div>
              </div>
              <div 
                className="columns"
                style={{ marginTop: `30px`, minHeight: `120px` }}
              >
                <div className="column is-3">
                  <a href={Risk} download>
                    <h1
                      className="title is-6"
                      style={{
                        borderTop: `3px solid #004D9B`,
                      }}
                    >
                      Terms of Reference – Risk & Governance Committee
                    </h1>
                  </a>
                </div>
                <div className="column is-3">
                  <a href={Ethics} download>
                    <h1
                      className="title is-6"
                      style={{
                        borderTop: `3px solid #004D9B`,
                      }}
                    >
                      Code Of Ethics
                    </h1>
                  </a>
                </div>
                
              </div>
            </section>
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}
